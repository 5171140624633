/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import CategoricalScheme from '../../CategoricalScheme';

const schemes = [
  {
    id: 'dtw_pbs1',
    label: 'DTWY Parauapebas',
    colors: [
      '#4891dd',
      '#ffcc00',
      '#4c9d4f',
      '#6f412e',
      '#eeeeee',
      '#858585',
      '#2c465f',
      '#6c5a12',
      '#2d4a2d',
      '#3a2a22',
      '#666665',
      '#414140',
      '#d1e3f6',
      '#fff2bf',
      '#d2e6d3',
      '#dbcfca',
      '#fafafa',
      '#e0e0e0',
      '#37638f',
      '#396a3a',
      '#9a9a9a',
    ],
  },
  {
    id: 'dtw_pbs2',
    label: 'DTWY Parauapebas 1A',
    colors: [
      '#ffcc00', // rausch
      '#4c9d4d', // hackb
      '#6f412e', // kazan
      '#eeeeee', // babu
      '#858585', // lima
      '#4891dd', // beach
      '#ffcc00', // barol
      '#4c9d4d',
      '#6f412e',
      '#eeeeee',
      '#858585',
      '#4891dd',
      '#ffcc00',
      '#4c9d4d',
      '#6f412e',
      '#eeeeee',
      '#858585',
      '#4891dd',
      '#ffcc00',
      '#4c9d4d',
    ],
  },
  {
    id: 'dtw_pbs3',
    label: 'DTWY Parauapebas 1M',
    colors: [
      '#6f412e', // rausch
      '#eeeeee', // hackb
      '#858585', // kazan
      '#4891dd', // babu
      '#ffcc00', // lima
      '#4c9d4d', // beach
      '#6f412e', // barol
      '#eeeeee',
      '#858585',
      '#4891dd',
      '#ffcc00',
      '#4c9d4d',
      '#6f412e',
      '#eeeeee',
      '#858585',
      '#4891dd',
      '#ffcc00',
      '#4c9d4d',
      '#6f412e',
      '#eeeeee',
    ],
  },
  {
    id: 'dtw_pbs4',
    label: 'DTWY Parauapebas 1V',
    colors: [
      '#4c9d4d', // rausch
      '#6f412e', // hackb
      '#eeeeee', // kazan
      '#858585', // babu
      '#4891dd', // lima
      '#ffcc00', // beach
      '#4c9d4d', // barol
      '#6f412e',
      '#eeeeee',
      '#858585',
      '#4891dd',
      '#ffcc00',
      '#4c9d4d',
      '#6f412e',
      '#eeeeee',
      '#858585',
      '#4891dd',
      '#ffcc00',
      '#4c9d4d',
      '#6f412e',
    ],
  },
  {
    id: 'dtw_pbs5',
    label: 'DTWY Parauapebas Amarelo',
    colors: [
      '#6c5a12', // rausch
      '#a5860b', // hackb
      '#c7a007', // kazan
      '#ffcc00', // babu
      '#ffd940', // lima
      '#ffe57f', // beach
      '#fff2bf', // barol
      '#6c5a12',
      '#a5860b',
      '#c7a007',
      '#ffcc00',
      '#ffd940',
      '#ffe57f',
      '#fff2bf',
      '#6c5a12',
      '#a5860b',
      '#c7a007',
      '#ffcc00',
      '#ffd940',
      '#ffe57f',
      '#fff2bf',
    ],
  },
  {
    id: 'dtw_pbs6',
    label: 'DTWY Parauapebas Azul',
    colors: [
      '#2c465f', // rausch
      '#37638f', // hackb
      '#3d74ad', // kazan
      '#4891dd', // babu
      '#76ade6', // lima
      '#a3c7ed', // beach
      '#d1e3f6', // barol
      '#2c465f',
      '#37638f',
      '#3d74ad',
      '#4891dd',
      '#76ade6',
      '#a3c7ed',
      '#d1e3f6',
      '#2c465f',
      '#37638f',
      '#3d74ad',
      '#4891dd',
      '#76ade6',
      '#a3c7ed',
      '#d1e3f6',
    ],
  },
  {
    id: 'dtw_pbs7',
    label: 'DTWY Parauapebas Cinza',
    colors: [
      '#414140', // rausch
      '#5b5b5b', // hackb
      '#6b6b6b', // kazan
      '#858585', // babu
      '#a4a4a4', // lima
      '#c1c1c1', // beach
      '#e0e0e0', // barol
      '#414140',
      '#5b5b5b',
      '#6b6b6b',
      '#858585',
      '#a4a4a4',
      '#c1c1c1',
      '#e0e0e0',
      '#414140',
      '#5b5b5b',
      '#6b6b6b',
      '#858585',
      '#a4a4a4',
      '#c1c1c1',
      '#e0e0e0',
    ],
  },
  {
    id: 'dtw_pbs8',
    label: 'DTWY Parauapebas AmAz',
    colors: [
      '#ffcc00', // rausch
      '#4891dd', // hackb
      '#ffd940', // kazan
      '#76ade6', // babu
      '#ffe57f', // lima
      '#a3c7ed', // beach
      '#fff2bf', // barol
      '#ffcc00',
      '#4891dd',
      '#ffd940',
      '#76ade6',
      '#ffe57f',
      '#a3c7ed',
      '#fff2bf',
      '#ffcc00',
      '#4891dd',
      '#ffd940',
      '#76ade6',
      '#ffe57f',
      '#a3c7ed',
    ],
  },
  {
    id: 'dtw_pbs9',
    label: 'DTWY Parauapebas Az V',
    colors: [
      '#4891dd', // rausch
      '#4c9d4f', // hackb
      '#76ade6', // kazan
      '#79b67b', // babu
      '#a3c7ed', // lima
      '#a5cda6', // beach
      '#d1e3f6', // barol
      '#4891dd',
      '#4c9d4f',
      '#76ade6',
      '#79b67b',
      '#a3c7ed',
      '#a5cda6',
      '#d1e3f6',
      '#4891dd',
      '#4c9d4f',
      '#76ade6',
      '#79b67b',
      '#a3c7ed',
      '#a5cda6',
    ],
  },
  {
    id: 'dtw_pbs10',
    label: 'DTWY Parauapebas C Az',
    colors: [
      '#858585', // rausch
      '#4891dd', // hackb
      '#a4a4a4', // kazan
      '#76ade6', // babu
      '#c1c1c1', // lima
      '#a3c7ed', // beach
      '#e0e0e0', // barol
      '#858585',
      '#4891dd',
      '#a4a4a4',
      '#76ade6',
      '#c1c1c1',
      '#a3c7ed',
      '#e0e0e0',
      '#858585',
      '#4891dd',
      '#a4a4a4',
      '#76ade6',
      '#c1c1c1',
      '#a3c7ed',
    ],
  },
  {
    id: 'dtw_pbs11',
    label: 'DTWY Parauapebas C Marr',
    colors: [
      '#858585', // rausch
      '#6f412e', // hackb
      '#a4a4a4', // kazan
      '#937162', // babu
      '#c1c1c1', // lima
      '#b69f96', // beach
      '#e0e0e0', // barol
      '#858585',
      '#6f412e',
      '#a4a4a4',
      '#937162',
      '#c1c1c1',
      '#b69f96',
      '#e0e0e0',
      '#858585',
      '#6f412e',
      '#a4a4a4',
      '#937162',
      '#c1c1c1',
      '#b69f96',
    ],
  },
  {
    id: 'dtw_pbs12',
    label: 'DTWY Parauapebas Marr Am',
    colors: [
      '#6f412e', // rausch
      '#ffcc00', // hackb
      '#937162', // kazan
      '#ffd940', // babu
      '#b69f96', // lima
      '#ffe57f', // beach
      '#dbcfca', // barol
      '#fff2bf',
      '#6f412e',
      '#ffcc00',
      '#937162',
      '#ffd940',
      '#b69f96',
      '#ffe57f',
      '#dbcfca',
      '#fff2bf',
      '#6f412e',
      '#ffcc00',
      '#937162',
      '#ffd940',
    ],
  },
  {
    id: 'dtw_pbs13',
    label: 'DTWY Parauapebas Marr V',
    colors: [
      '#6f412e', // rausch
      '#4c9d4f', // hackb
      '#937162', // kazan
      '#79b67b', // babu
      '#b69f96', // lima
      '#a5cda6', // beach
      '#dbcfca', // barol
      '#6f412e',
      '#4c9d4f',
      '#937162',
      '#79b67b',
      '#b69f96',
      '#a5cda6',
      '#dbcfca',
      '#6f412e',
      '#4c9d4f',
      '#937162',
      '#79b67b',
      '#b69f96',
      '#a5cda6',
    ],
  },
  {
    id: 'dtw_pbs14',
    label: 'DTWY Parauapebas V Am',
    colors: [
      '#4c9d4f', // rausch
      '#ffcc00', // hackb
      '#79b67b', // kazan
      '#ffd940', // babu
      '#a5cda6', // lima
      '#ffe57f', // beach
      '#d2e6d3', // barol
      '#4c9d4f',
      '#ffcc00',
      '#79b67b',
      '#ffd940',
      '#a5cda6',
      '#ffe57f',
      '#d2e6d3',
      '#4c9d4f',
      '#ffcc00',
      '#79b67b',
      '#ffd940',
      '#a5cda6',
      '#ffe57f',
    ],
  },
  {
    id: 'dtw_pbs15',
    label: 'DTWY Parauapebas Marrom',
    colors: [
      '#4e3326', // rausch
      '#5a3829', // hackb
      '#6f412e', // kazan
      '#937162', // babu
      '#b69f96', // lima
      '#dbcfca', // beach
      '#e7d7d1', // barol
      '#4e3326',
      '#5a3829',
      '#6f412e',
      '#937162',
      '#b69f96',
      '#dbcfca',
      '#e7d7d1',
      '#4e3326',
      '#5a3829',
      '#6f412e',
      '#937162',
      '#b69f96',
      '#dbcfca',
      '#e7d7d1',
    ],
  },
  {
    id: 'dtw_pbs16',
    label: 'DTWY Parauapebas CMAm',
    colors: [
      '#858585', // rausch
      '#6f412e', // hackb
      '#ffcc00', // kazan
      '#a4a4a4', // babu
      '#937162', // lima
      '#ffd940', // beach
      '#c1c1c1', // barol
      '#858585',
      '#6f412e',
      '#ffcc00',
      '#a4a4a4',
      '#937162',
      '#ffd940',
      '#c1c1c1',
      '#858585',
      '#6f412e',
      '#ffcc00',
      '#a4a4a4',
      '#937162',
      '#ffd940',
      '#c1c1c1',
    ],
  },
  {
    id: 'dtw_pbs17',
    label: 'DTWY Parauapebas CMAz',
    colors: [
      '#858585', // rausch
      '#6f412e', // hackb
      '#4891dd', // kazan
      '#a4a4a4', // babu
      '#937162', // lima
      '#76ade6', // beach
      '#c1c1c1', // barol
      '#858585',
      '#6f412e',
      '#4891dd',
      '#a4a4a4',
      '#937162',
      '#76ade6',
      '#c1c1c1',
      '#858585',
      '#6f412e',
      '#4891dd',
      '#a4a4a4',
      '#937162',
      '#76ade6',
      '#c1c1c1',
    ],
  },
  {
    id: 'dtw_pbs18',
    label: 'DTWY Parauapebas MVAm',
    colors: [
      '#6f412e', // rausch
      '#4c9d4f', // hackb
      '#ffcc00', // kazan
      '#937162', // babu
      '#79b67b', // lima
      '#ffd940', // beach
      '#b69f96', // barol
      '#6f412e',
      '#4c9d4f',
      '#ffcc00',
      '#937162',
      '#79b67b',
      '#ffd940',
      '#b69f96',
      '#6f412e',
      '#4c9d4f',
      '#ffcc00',
      '#937162',
      '#79b67b',
      '#ffd940',
      '#b69f96',
    ],
  },
  {
    id: 'dtw_pbs19',
    label: 'DTWY Parauapebas Verde 2',
    colors: [
      '#2d4a2d', // rausch
      '#396a3a', // hackb
      '#407d42', // kazan
      '#4c9d4f', // babu
      '#79b67b', // lima
      '#a5cda6', // beach
      '#d2e6d3', // barol
      '#2d4a2d',
      '#396a3a',
      '#407d42',
      '#4c9d4f',
      '#79b67b',
      '#a5cda6',
      '#d2e6d3',
      '#2d4a2d',
      '#396a3a',
      '#407d42',
      '#4c9d4f',
      '#79b67b',
      '#a5cda6',
      '#d2e6d3',
    ],
  },
  {
    id: 'dtw_pbs20',
    label: 'DTWY Parauapebas Azul 2',
    colors: [
      '#13293d', // rausch
      '#006494', // hackb
      '#247ba0', // kazan
      '#1b98e0', // babu
      '#d3ddde', // lima
      '#13293d', // beach
      '#006494', // barol
      '#247ba0',
      '#1b98e0',
      '#d3ddde',
      '#13293d',
      '#006494',
      '#247ba0',
      '#1b98e0',
      '#d3ddde',
      '#13293d',
      '#006494',
      '#247ba0',
      '#1b98e0',
      '#d3ddde',
    ],
  },
  {
    id: 'dtw_pbs21',
    label: 'DTWY Parauapebas Colorida',
    colors: [
      '#38255f', // rausch
      '#6a5092', // hackb
      '#a2b802', // kazan
      '#e2c900', // babu
      '#e46341', // lima
      '#38255f', // beach
      '#6a5092', // barol
      '#a2b802',
      '#e2c900',
      '#e46341',
      '#38255f',
      '#6a5092',
      '#a2b802',
      '#e2c900',
      '#e46341',
      '#38255f',
      '#6a5092',
      '#a2b802',
      '#e2c900',
      '#e46341',
    ],
  },
  {
    id: 'dtw_pbs22',
    label: 'DTWY Parauapebas Quente',
    colors: [
      '#a8006d', // rausch
      '#d3014c', // hackb
      '#ff3801', // kazan
      '#ff7f00', // babu
      '#ffab00', // lima
      '#a8006d', // beach
      '#d3014c', // barol
      '#ff3801',
      '#ff7f00',
      '#ffab00',
      '#a8006d',
      '#d3014c',
      '#ff3801',
      '#ff7f00',
      '#ffab00',
      '#a8006d',
      '#d3014c',
      '#ff3801',
      '#ff7f00',
      '#ffab00',
    ],
  },
  {
    id: 'dtw_pbs23',
    label: 'DTWY Parauapebas Fria',
    colors: [
      '#140850', // rausch
      '#254a7f', // hackb
      '#377a97', // kazan
      '#4eadaf', // babu
      '#88deb1', // lima
      '#140850', // beach
      '#254a7f', // barol
      '#377a97',
      '#4eadaf',
      '#88deb1',
      '#140850',
      '#254a7f',
      '#377a97',
      '#4eadaf',
      '#88deb1',
      '#140850',
      '#254a7f',
      '#377a97',
      '#4eadaf',
      '#88deb1',
    ],
  },
  {
    id: 'dtw_pbs24',
    label: 'DTWY Parauapebas Fria 2',
    colors: [
      '#4d4497', // rausch
      '#646ab4', // hackb
      '#7583c2', // kazan
      '#8a9bcf', // babu
      '#b6ceea', // lima
      '#4d4497', // beach
      '#646ab4', // barol
      '#7583c2',
      '#8a9bcf',
      '#b6ceea',
      '#4d4497',
      '#646ab4',
      '#7583c2',
      '#8a9bcf',
      '#b6ceea',
      '#4d4497',
      '#646ab4',
      '#7583c2',
      '#8a9bcf',
      '#b6ceea',
    ],
  },
  {
    id: 'dtw_pbs25',
    label: 'DTWY Parauapebas Natureza',
    colors: [
      '#928959', // rausch
      '#b3ac61', // hackb
      '#dac386', // kazan
      '#dba057', // babu
      '#c47056', // lima
      '#928959', // beach
      '#b3ac61', // barol
      '#dac386',
      '#dba057',
      '#c47056',
      '#928959',
      '#b3ac61',
      '#dac386',
      '#dba057',
      '#c47056',
      '#928959',
      '#b3ac61',
      '#dac386',
      '#dba057',
      '#c47056',
    ],
  },
  {
    id: 'dtw_pbs26',
    label: 'DTWY Parauapebas Terra',
    colors: [
      '#2d1012', // rausch
      '#6f362f', // hackb
      '#914e3b', // kazan
      '#b26c4a', // babu
      '#d58c58', // lima
      '#f6b166', // beach
      '#2d1012', // barol
      '#6f362f',
      '#914e3b',
      '#b26c4a',
      '#d58c58',
      '#f6b166',
      '#2d1012',
      '#6f362f',
      '#914e3b',
      '#b26c4a',
      '#d58c58',
      '#f6b166',
      '#2d1012',
      '#6f362f',
    ],
  },
].map(s => new CategoricalScheme(s));

export default schemes;
